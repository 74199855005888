import React from 'react';
import Img from 'gatsby-image';

import Layout from '../layouts/index';

import Downloads from '../images/downloads.jpg';

const links = [
  {
    title: 'Bundesverband freier Immobilien- und Wohnungsunternehen',
    url: 'http://www.bfw-bund.de/',
    icon:
      '/samples/links/bundesverband-freier-immobilien-und-wohnungsunternehen/logo2.png',
  },
  {
    title: 'Kölner Haus- und Grundbesitzerverein von 1888',
    url: 'http://www.koelner-hug.de/',
    icon:
      '/samples/links/koelner-haus-und-grundbesitzerverein-von-1888/logo-koelner-2hug.png',
  },
  {
    title: 'Schufa / Schufa-Auskunft beantragen',
    url: 'https://www.meineschufa.de/index.php',
    icon:
      '/samples/links/schufa-strich-schufa-auskunft-beantragen/fl-schufa-logo.png',
  },
  {
    title: 'Verbraucherzentrale / Richtiges Heizen und Lüften',
    url:
      'http://www.verbraucherzentrale-energieberatung.de/downloads/VZE_Broschuere_HeizenLueften.pdf',
    icon:
      '/samples/links/verbraucherzentrale-strich-richtiges-heizen-und-lueften/vzbvnrg_energieberatung1.gif.jpeg',
  },
  {
    title: 'Umweltbundesamt / Richtiges Heizen und Lüften',
    url:
      'http://www.umweltbundesamt.de/themen/gesundheit/umwelteinfluesse-auf-den-menschen/schimmel/richtig-lueften-schimmelbildung-vermeiden',
    icon:
      '/samples/links/umweltbundesamt-strich-richtiges-heizen-und-lueften/logo-1.png',
  },
];

const donwloads = [
  {
    title: 'Formular SEPA-Lastschriftmandat',
    url:
      '/samples/downloads/formular-sepa-lastschriftmandat/Formular_SEPA-Lastschriftmandat.pdf',
  },
  {
    title: 'Formular Änderung der Bankverbindung',
    url:
      '/samples/downloads/formular-aenderung-der-bankverbindung/Formular_Aenderung_der_Bankverbindung.pdf',
  },
  {
    title: 'Formular Mieterselbstauskunft',
    url:
      '/samples/downloads/formular-mieterselbstauskunft/Formular_Mieterselbstauskunft.pdf',
  },
  {
    title: 'Formular Notfall Telefonnummern',
    url:
      '/samples/downloads/formular-notfall-telefonnummern/Formular_Notfall_Telefonnummern.pdf',
  },
];

const DonwloadPage = ({ data, ...props }) => {
  console.log(data);
  return (
    <Layout {...props}>
      <div className="container">
        <Img fluid={data.downloads.childImageSharp.fluid} />

        <div className="row">
          <div className="col-sm-6">
            <h3>Links</h3>
            <br />
            <br />
            {links.map(link => (
              <div className="row">
                <span className="col-sm-3">
                  <img className="link-icon" src={link.icon} />
                </span>
                <a href={link.url}>{link.title}</a>
                <br />
                <br />
              </div>
            ))}
          </div>
          <div className="col-sm-6">
            <h3>Downloads.</h3>
            <br />
            <br />
            {donwloads.map(download => (
              <div className="row">
                <span>
                  {download.title} <br />
                </span>
                <a
                  className="btn btn-primary"
                  href={download.url}
                  download={`${download.title}.pdf`}
                >
                  Download
                </a>
                <br />
                <br />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DonwloadPage;

export const query = graphql`
  query {
    downloads: file(relativePath: { eq: "downloads.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
